import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=9e6cb50e&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=9e6cb50e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e6cb50e",
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/test/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BFormInput, BCol, BFormText, BFormCheckboxGroup, BFormGroup, BRow, BCard, BFormCheckbox, BFormTextarea, BButton, BFormFile, BAlert, BForm, BContainer} from 'bootstrap-vue'
    installComponents(component, {BFormInput, BCol, BFormText, BFormCheckboxGroup, BFormGroup, BRow, BCard, BFormCheckbox, BFormTextarea, BButton, BFormFile, BAlert, BForm, BContainer})
    

export default component.exports